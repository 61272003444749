import React from 'react'
import TitleSection from '../../Molecules/TitleSection/TitleSection'
import './BlockExpertise.scss'
import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground'
const BlockExpertise = ({ ExpTitle, ExpText, ExpTextlist }) => {

    return (
        <div className='blockexpertise'>

            <div className="wrapper_page_xs">
                <TitleSection
                    title={ExpTitle}
                    h2color="white"
                />
                <div className='flex_content'>
                    <div className='txt-wrapper' dangerouslySetInnerHTML={{ __html: ExpText }} />
                </div>

                <div className='bullet_wrapper'>
                    {
                        ExpTextlist.map((list, index) => {
                            return (
                                <div className='list-wrap' dangerouslySetInnerHTML={{ __html: list.processed }} key={index}>
                                </div>
                            )
                        })

                    }
                </div>

            </div>

        </div>
    )
}

export default BlockExpertise