import React, { useRef, useEffect, useState } from 'react';

import SimpleReactValidator from 'simple-react-validator';

import {
  FormattedMessage,
  useIntl,
} from '../../../plugins/publicis-gatsby-plugin-i18n';
import { Button } from '../../Molecules/Button/Button';
import Checkbox from '../../Molecules/Checkbox/Checkbox';
import Input from '../../Molecules/Input/Input';
import Textarea from '../../Molecules/Textarea/Textarea';
import Dropdown from '../../Molecules/Dropdown/Dropdown';

import './ContactForm.Expertise.scss';
const axios = require('axios').default;
let classNames = require('classnames');

const ContactFormExpertise = ({ formName, langCode, title, pagesContact }) => {
  const [, updateState] = React.useState();
  const intl = useIntl();

  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [lastname, setLastname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [acceptCondition, setAcceptCondition] = useState(false);
  const [organisationName, setOrganisationName] = useState('');
  const [fuctionName, setFuctionName] = useState('');
  const [phone, setPhone] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const [communeName, setCommuneName] = useState('');
  const [typeDept, setTypeDept] = useState('');
  const webFormSessionUrl = `${process.env.GATSBY_DRUPAL_BASE_URL}session/token`;
  const webFormSubmitUrl = `${process.env.GATSBY_DRUPAL_BASE_URL}${langCode}/webform_rest/submit?_format=json`;

  useEffect(() => {
    setTypeDept(pagesContact[0].label);
  }, []);

  const locale = {
    accepted: intl.messages['common.form.error.accepted'],
    alpha: intl.messages['common.form.error.alpha'],
    alpha_space: intl.messages['common.form.error.alpha_space'],
    boolean: intl.messages['common.form.error.boolean'],
    email: intl.messages['common.form.error.email'],
    numeric: intl.messages['common.form.error.numeric'],
    phone: intl.messages['common.form.error.phone'],
    regex: intl.messages['common.form.error.regex'],
    required: intl.messages['common.form.error.required'],
    string: intl.messages['common.form.error.string'],
    typeof: intl.messages['common.form.error.typeof'],
  };

  SimpleReactValidator.addLocale(langCode, locale);

  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      locale: langCode,
    })
  );

  const handleSubmitForm = (e) => {
    if (validator.current.allValid()) {
      setIsError(false);
      axios.get(webFormSessionUrl).then((result) => {
        if (200 === result.status) {
          let _paq = (window._paq = window._paq || []);
          _paq.push([
            'trackEvent',
            'expert_page',
            'submit button click',
            'email',
            email,
          ]);
          const csrfToken = result.data;
          fetch(webFormSubmitUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrfToken,
            },
            body: JSON.stringify({
              webform_id: formName,
              page_title: title,
              surname: lastname,
              name: firstname,
              message: message,
              email: email,
              organisation: organisationName,
              fonction: fuctionName,
              telephone: phone,
              departement: departmentName,
              commune: communeName,
              type: typeDept,
            }),
          })
            .then((response) => response.json())
            .then(
              (data) => {
                //on success you do whatever you will want
                //console.log('success', data);
                setIsEmailSent(true);
                window.scrollTo({
                  top:
                    document.querySelector('.section_contact_form').offsetTop -
                    100,
                  behavior: 'smooth',
                });
              },
              (error) => {
                console.log('error', error);
              }
            );
        }
      });
    } else {
      validator.current.showMessages();
      setIsError(true);
    }
  };

  const deptHandler = (val) => {
    setTypeDept(val.label);
  };

  return (
    <div className="contact_form_container">
      {isError && (
        <FormattedMessage id="common.form.required">
          {(x) => <span className="red">* {x}</span>}
        </FormattedMessage>
      )}
      {isEmailSent && (
        <div className="email-sent">
          <h3 aria-live="polite">
            <FormattedMessage id="common.form.email_sent" />
          </h3>
        </div>
      )}
      {!isEmailSent && (
        <form>
          <Input
            validator={validator.current}
            field={{
              id: 'lastnamexx',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({
                id: 'contactexpertise.form.field.lastname.placeholder',
              }),
              name: 'lastnamexx',
              label: intl.formatMessage({
                id: 'contactexpertise.form.field.lastname.label',
              }),
              value: lastname,
              textClear: intl.formatMessage({
                id: 'contactexpertise.form.field.clear_field',
              }),
            }}
            reset={() => setLastname('')}
            change={(value) => setLastname(value)}
          />
          <Input
            validator={validator.current}
            field={{
              id: 'firstnamexx',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({
                id: 'contactexpertise.form.field.firstname.placeholder',
              }),
              name: 'firstnamexx',
              label: intl.formatMessage({
                id: 'contactexpertise.form.field.firstname.label',
              }),
              value: firstname,
              textClear: intl.formatMessage({
                id: 'contactexpertise.form.field.clear_field',
              }),
            }}
            reset={() => setFirstname('')}
            change={(value) => setFirstname(value)}
          />
          <div className="input">
            <Dropdown
              customClass="dropdown-questions-footer"
              list={pagesContact}
              DropDownTitle={intl.formatMessage({
                id: 'contactexpertise.form.field.type.label',
              })}
              onChange={deptHandler}
            />
          </div>

          <Input
            validator={validator.current}
            field={{
              id: 'organisationNamexx',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({
                id: 'contactexpertise.form.field.orgName.placeholder',
              }),
              name: 'organisationnamexx',
              label: intl.formatMessage({
                id: 'contactexpertise.form.field.orgName.label',
              }),
              value: organisationName,
              textClear: intl.formatMessage({
                id: 'contactexpertise.form.field.clear_field',
              }),
            }}
            reset={() => setOrganisationName('')}
            change={(value) => setOrganisationName(value)}
          />

          <Input
            validator={validator.current}
            field={{
              id: 'fuctionNamexx',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({
                id: 'contactexpertise.form.field.orgFunction.placeholder',
              }),
              name: 'functionnamexx',
              label: intl.formatMessage({
                id: 'contactexpertise.form.field.orgFunction.label',
              }),
              value: fuctionName,
              textClear: intl.formatMessage({
                id: 'contactexpertise.form.field.clear_field',
              }),
            }}
            reset={() => setFuctionName('')}
            change={(value) => setFuctionName(value)}
          />

          <Input
            validator={validator.current}
            field={{
              id: 'emailxx',
              filed_type: 'input',
              type: 'email',
              placeholder: intl.formatMessage({
                id: 'contactexpertise.form.field.email.placeholder',
              }),
              name: 'emailxx',
              label: intl.formatMessage({
                id: 'contactexpertise.form.field.email.label',
              }),
              value: email,
              textClear: intl.formatMessage({
                id: 'contactexpertise.form.field.clear_field',
              }),
            }}
            reset={() => setEmail('')}
            change={(value) => setEmail(value)}
          />

          <Input
            field={{
              id: 'phonexx',
              filed_type: 'input',
              type: 'input',
              placeholder: intl.formatMessage({
                id: 'contactexpertise.form.field.phone.placeholder',
              }),
              name: 'phonexx',
              label: intl.formatMessage({
                id: 'contactexpertise.form.field.phone.label',
              }),
              value: phone,
              textClear: intl.formatMessage({
                id: 'contactexpertise.form.field.clear_field',
              }),
            }}
            optional={true}
            reset={() => setPhone('')}
            change={(value) => setPhone(value)}
          />
          <Input
            field={{
              id: 'DepartmentNamexx',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({
                id: 'contactexpertise.form.field.dept.placeholder',
              }),
              name: 'DepartmentNamexx',
              label: intl.formatMessage({
                id: 'contactexpertise.form.field.dept.label',
              }),
              value: departmentName,
              textClear: intl.formatMessage({
                id: 'contactexpertise.form.field.clear_field',
              }),
            }}
            reset={() => setDepartmentName('')}
            change={(value) => setDepartmentName(value)}
          />
          {/*//communeName*/}
          <Input
            field={{
              id: 'CommuneNamexx',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({
                id: 'contactexpertise.form.field.commuine.placeholder',
              }),
              name: 'CommuneNamexx',
              label: intl.formatMessage({
                id: 'contactexpertise.form.field.commuine.label',
              }),
              value: communeName,
              textClear: intl.formatMessage({
                id: 'contactexpertise.form.field.clear_field',
              }),
            }}
            reset={() => setCommuneName('')}
            change={(value) => setCommuneName(value)}
          />
          <Textarea
            validator={validator.current}
            field={{
              id: 'messagexx',
              filed_type: 'textarea',
              type: 'textarea',
              placeholder: intl.formatMessage({
                id: 'contactexpertise.form.field.message.placeholder',
              }),
              name: 'messagexx',
              label: intl.formatMessage({
                id: 'contactexpertise.form.field.message.label',
              }),
              value: message,
              textClear: intl.formatMessage({
                id: 'contactexpertise.form.field.clear_field',
              }),
            }}
            reset={() => {
              setMessage('');
            }}
            change={(value) => {
              setMessage(value);
            }}
          />
          <Checkbox
            validator={validator.current}
            field={{
              id: 'accept_checkbox',
              filed_type: 'checkbox',
              type: 'checkbox',
              name: 'chkBox', //ReturnTranslation('form-accept', langCode),
              label: intl.formatMessage({ id: 'contactexpertise.compulsory' }), //ReturnTranslation('form-accept', langCode) + '*',
              value: acceptCondition,
            }}
            change={(id, value) => setAcceptCondition(value)}
          />

          <div className="btn_container btn-center">
            <Button
              id="submit"
              primary={true}
              label={intl.formatMessage({ id: 'contactexpertise.cta.submit' })}
              onClick={(e) => {
                e.preventDefault();
                handleSubmitForm(e);
              }}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactFormExpertise;
